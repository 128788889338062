import React, { useCallback, useContext, useEffect, useState } from "react";

import TimeAgo from "../../Services/TimeAgo";

import { Drawer, Alert, Badge } from "antd";
import { BellOutlined } from "@ant-design/icons";

import SocketContext from "../../Context/SocketContext";

type TNotification = {
  date: number;
  message: string;
  read: boolean;
};

export default function Notifications() {
  const [visible, setVisible] = useState<boolean>(false);
  const [notifications, setNotifications] = useState<TNotification[]>([]);

  const socket = useContext(SocketContext);

  useEffect(() => {
    socket?.on("toast", (message: string) => {
      let stored_notifications = notifications;
      stored_notifications.push({ date: Date.now(), message, read: visible });

      setNotifications(stored_notifications);
    });
  }, [socket, visible, notifications]);

  const ToggleDrawer = useCallback(() => {
    if (!visible) {
      let stored_notifications = notifications;

      for (let i = 0; i < stored_notifications.length; i++) {
        stored_notifications[i].read = true;
      }

      setNotifications(stored_notifications);
      setVisible(!visible);
    } else setVisible(!visible);
  }, [visible, notifications]);

  return (
    <React.Fragment>
      <span onClick={() => ToggleDrawer()}>
        <BellOutlined />

        <span>Notifications</span>

        <Badge
          size="small"
          offset={[5, 0]}
          count={notifications.filter((x) => !x.read).length}
        />
      </span>

      <Drawer
        title={<>Notifications</>}
        placement="right"
        closable={true}
        onClose={() => ToggleDrawer()}
        visible={visible}
        width={550}
      >
        {notifications
          .sort((a, b) => b.date - a.date)
          .map((notification, index) => (
            <Alert
              className={index > 0 ? "mt-2" : ""}
              key={index}
              message={`${TimeAgo.format(notification.date)} - ${
                notification.message
              }`}
              type="info"
            />
          ))}

        {notifications.length === 0 && <p>wow, much empty</p>}
      </Drawer>
    </React.Fragment>
  );
}
