import openSocket, { Socket } from "socket.io-client";
import { DefaultEventsMap } from "socket.io-client/build/typed-events";

const socket: Socket<DefaultEventsMap, DefaultEventsMap> = openSocket(
  "https://staff-api.rustlegends.com",
  { withCredentials: true }
);

const Export = {
  on: (name: string, cb: (x: any) => any) =>
    socket.on(name, (data: any) => cb(data)),

  emit: (name: string, args: any) => socket.emit(name, args),

  off: socket.off,
};

export default Export;
