import { Link } from "react-router-dom";

import { Menu, Layout } from "antd";

import {
  HomeOutlined,
  ToolOutlined,
  UserOutlined,
  HistoryOutlined,
  FormOutlined,
  ApiOutlined,
  CopyOutlined,
  UnlockOutlined,
  FolderViewOutlined,
  LineChartOutlined,
  DollarCircleOutlined,
  SendOutlined,
  IdcardOutlined,
  OrderedListOutlined,
} from "@ant-design/icons";

import Notifications from "../Notifications/Notifications";

import useSettings from "../../Services/HTTP/useSettings";

import "./NavSider.css";

export default function NavSider() {
  const { data: settings } = useSettings();

  const { Sider } = Layout;

  return (
    <Sider
      style={{
        overflow: "auto",
        height: "100vh",
        position: "fixed",
        left: 0,
        zIndex: 1,
      }}
      collapsed={true}
      collapsible={false}
    >
      <Menu mode="vertical">
        <Menu.Item icon={<HomeOutlined />}>
          <Link to="/home">Home</Link>
        </Menu.Item>

        <Menu.SubMenu icon={<ToolOutlined />}>
          <Menu.Item>
            <Link to="/action/ban">Ban</Link>
          </Menu.Item>

          {settings?.admin.is("history_moderator") && (
            <Menu.Item>
              <Link to="/action/unban">Unban</Link>
            </Menu.Item>
          )}

          <Menu.Item>
            <Link to="/action/glban">GLBan</Link>
          </Menu.Item>

          <Menu.Item>
            <Link to="/action/mute">Mute</Link>
          </Menu.Item>

          <Menu.Item>
            <Link to="/action/unmute">Unmute</Link>
          </Menu.Item>

          <Menu.Item>
            <Link to="/action/vip">VIP</Link>
          </Menu.Item>

          <Menu.Item>
            <Link to="/action/wpvip">Wipe Pass VIP</Link>
          </Menu.Item>

          <Menu.Item>
            <Link to="/action/kick">Kick</Link>
          </Menu.Item>

          {/* <Menu.Item>
            <Link to="/action/vpn">VPN</Link>
          </Menu.Item> */}

          {settings?.admin?.is("force_restart") && (
            <Menu.Item>
              <Link to="/action/restart">Restart</Link>
            </Menu.Item>
          )}
        </Menu.SubMenu>

        <Menu.Item icon={<UserOutlined />}>
          <Link to="/players">Players</Link>
        </Menu.Item>

        <Menu.Item icon={<SendOutlined />}>
          <Link to="/tickets">Tickets</Link>
        </Menu.Item>

        <Menu.Item icon={<OrderedListOutlined />}>
          <Link to="/spectates">Spectate Queue</Link>
        </Menu.Item>

        <Menu.Item icon={<IdcardOutlined />}>
          <Link to="/whitelist-requests">Whitelist Requests</Link>
        </Menu.Item>

        <Menu.Item icon={<DollarCircleOutlined />}>
          <Link to="/purchases">Purchases</Link>
        </Menu.Item>

        <Menu.Item icon={<LineChartOutlined />}>
          <Link to="/player-stats-summary">Player Stats Summary</Link>
        </Menu.Item>

        <Menu.Item icon={<HistoryOutlined />}>
          <Link to="/history">History</Link>
        </Menu.Item>

        {settings?.admin?.is("senior_admin") && (
          <Menu.Item icon={<FormOutlined />}>
            <Link to="/appeals">Appeals</Link>
          </Menu.Item>
        )}

        {settings?.admin?.is("history_moderator") && (
          <Menu.Item icon={<FolderViewOutlined />}>
            <Link to="/review-bans">Review Bans</Link>
          </Menu.Item>
        )}

        {(settings?.admin?.is("owner") ||
          settings?.admin?.is("ip_ban_manager")) && (
          <Menu.SubMenu icon={<ApiOutlined />}>
            <Menu.Item>
              <Link to="/ip-bans">IP Bans</Link>
            </Menu.Item>

            <Menu.Item>
              <Link to="/expired-ip-bans">Expired IP Bans</Link>
            </Menu.Item>
          </Menu.SubMenu>
        )}

        {settings?.admin?.is("owner") && (
          <Menu.SubMenu icon={<UnlockOutlined />}>
            <Menu.Item>
              <Link to="/owner/admins">Admins</Link>
            </Menu.Item>

            <Menu.Item>
              <Link to="/owner/creators">Creators</Link>
            </Menu.Item>

            <Menu.Item>
              <Link to="/owner/bans">Bans</Link>
            </Menu.Item>

            <Menu.Item>
              <Link to="/owner/server-versions">Server Versions</Link>
            </Menu.Item>

            <Menu.Item>
              <Link to="/owner/sessions">Sessions</Link>
            </Menu.Item>
          </Menu.SubMenu>
        )}

        <Menu.Item itemIcon={<Notifications />} />
      </Menu>
    </Sider>
  );
}
