import axios from "axios";

const rustlegends = axios.create({
  withCredentials: true,
  baseURL: window.location.hostname.includes("localhost")
    ? "http://localhost:5004"
    : "https://staff-api.rustlegends.com",
});

rustlegends.interceptors.request.use((config) => {
  const JWToken = window.localStorage.getItem("jwt");

  if (JWToken) {
    config.headers["x-jwt-token"] = JWToken;
  }

  return config;
});

export default {
  get: rustlegends.get,
  post: rustlegends.post,
  delete: rustlegends.delete,
};
