import { Suspense } from "react";
import { Container, Row, Col } from "react-bootstrap";

import { Layout, Alert, Spin } from "antd";

import SocketService from "./Services/SocketService";

import SocketContext from "./Context/SocketContext";

import { ToastContainer } from "react-toastify";

import SuspenseFallback from "./Components/Suspense/SuspenseFallback";
import NavSider from "./Components/Sider/NavSider";
import Routes from "./Components/Routes/Routes";
import useSettings from "./Services/HTTP/useSettings";

const { Content } = Layout;

export default function App() {
  const { data: settings, isLoading: loading } = useSettings();

  return (
    <SocketContext.Provider value={SocketService}>
      <Layout style={{ minHeight: "100vh" }}>
        <Layout className="site-layout">
          <div className="page-header">Rust Legends</div>

          <div className="page-content">
            <Content style={{ margin: "0 16px" }}>
              {!settings && loading && (
                <Container>
                  <Row className="pt-4">
                    <Col md="12">
                      <Spin>
                        <Alert
                          message="Loading..."
                          description="Please wait while we load your user profile."
                          type="info"
                        />
                      </Spin>
                    </Col>
                  </Row>
                </Container>
              )}

              {!settings && !loading && (
                <Container>
                  <Row className="pt-4">
                    <Col>
                      <Alert
                        message="Error"
                        description={
                          <>
                            <p className="tw-mb-0">
                              Please sign in with a priviliged account!
                            </p>

                            <a href="https://staff-api.rustlegends.com/auth/steam">
                              Sign In
                            </a>
                          </>
                        }
                        type="error"
                      />
                    </Col>
                  </Row>
                </Container>
              )}

              <ToastContainer pauseOnFocusLoss={false} pauseOnHover={false} />

              <Suspense fallback={<SuspenseFallback />}>
                {settings && !loading && <Routes />}
              </Suspense>
            </Content>
          </div>
        </Layout>

        <NavSider />
      </Layout>
    </SocketContext.Provider>
  );
}
