import { Redirect, Route } from "react-router-dom";
import useSettings from "../../../Services/HTTP/useSettings";

const ProtectedRoute = ({ component: Component, role, ...rest }) => 
{
    const { data: settings } = useSettings();

    return (
        <Route 
            {...rest} 
            render={(props) => (
                settings.admin?.is(role)
                    ? <Component {...props} />
                    : <Redirect to='/home' />
            )} 
        />
    );
}

export default ProtectedRoute;