import { Container, Row, Col } from "react-bootstrap";
import { Alert, Spin } from "antd";

export default function SuspenseFallback()
{
    return (
        <Container>
            <Row className="pt-4">
                <Col md="12">
                    <Spin>
                        <Alert
                            message="Loading..."
                            description="Please wait while we load the content..."
                            type="info"
                        />
                    </Spin>
                </Col>
            </Row>
        </Container>
    );
};