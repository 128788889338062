import { lazy } from "react";
import { Switch, Route, Redirect } from "react-router-dom";

import pMinDelay from "p-min-delay";
import ProtectedRoute from "./Sub-Components/ProtectedRoute";

const DELAY = 250;

const Action = lazy(() =>
  pMinDelay(import("../Pages/Action/Home/Action"), DELAY)
);

const Appeal = lazy(() => pMinDelay(import("../Pages/Appeals/Appeal"), DELAY));

const Appeals = lazy(() =>
  pMinDelay(import("../Pages/Appeals/Appeals"), DELAY)
);

const AppealStatistics = lazy(() =>
  pMinDelay(import("../Pages/Appeals/AppealStatistics"), DELAY)
);

const Home = lazy(() => pMinDelay(import("../Pages/Home/Home"), DELAY));

const History = lazy(() =>
  pMinDelay(import("../Pages/History/History"), DELAY)
);

const Players = lazy(() =>
  pMinDelay(import("../Pages/Players/Players"), DELAY)
);

const Player = lazy(() => pMinDelay(import("../Pages/Players/Player"), DELAY));

const PlayerStatsSummary = lazy(() =>
  pMinDelay(import("../Pages/Players/PlayerStatsSummary"), DELAY)
);

const Bans = lazy(() => pMinDelay(import("../Pages/Bans/Bans"), DELAY));

const ReviewBans = lazy(() =>
  pMinDelay(import("../Pages/Bans/ReviewBans"), DELAY)
);

const IPBans = lazy(() => pMinDelay(import("../Pages/IPBans/IPBans"), DELAY));

const ExpiredIPBans = lazy(() =>
  pMinDelay(import("../Pages/ExpiredIPBans/ExpiredIPBans"), DELAY)
);

const IDTeams = lazy(() => pMinDelay(import("../Pages/Teams/IDTeams"), DELAY));

const PlayerTeams = lazy(() =>
  pMinDelay(import("../Pages/Teams/PlayerTeams"), DELAY)
);

const VPNApproved = lazy(() =>
  pMinDelay(import("../Pages/VPNApproved/VPNApproved"), DELAY)
);

const ServerUpdates = lazy(() =>
  pMinDelay(import("../Pages/Server Updates/ServerUpdates"), DELAY)
);

const ServerVersions = lazy(() =>
  pMinDelay(import("../Pages/Server Versions/ServerVersions"), DELAY)
);

const Sessions = lazy(() =>
  pMinDelay(import("../Pages/Sessions/Sessions"), DELAY)
);

const Tickets = lazy(() =>
  pMinDelay(import("../Pages/Tickets/Tickets"), DELAY)
);

const Ticket = lazy(() => pMinDelay(import("../Pages/Tickets/Ticket"), DELAY));

const Creators = lazy(() =>
  pMinDelay(import("../Pages/Creators/Creators"), DELAY)
);

const Purchases = lazy(() =>
  pMinDelay(import("../Pages/Purchases/Purchases"), DELAY)
);

const Admins = lazy(() => pMinDelay(import("../Pages/Admins/Admins"), DELAY));

const WhitelistRequests = lazy(() =>
  pMinDelay(import("../Pages/Whitelist/WhitelistRequests"), DELAY)
);

const WhitelistRequest = lazy(() =>
  pMinDelay(import("../Pages/Whitelist/WhitelistRequest"), DELAY)
);

const Spectates = lazy(() =>
  pMinDelay(import("../Pages/Spectates/Spectates"), DELAY)
);

export default function Routes() {
  return (
    <Switch>
      <Route path="/home" component={Home} />
      <Route path="/history" component={History} />

      <Route path="/purchases" component={Purchases} />

      <ProtectedRoute
        role="senior_admin"
        path="/appeal/:id"
        component={Appeal}
      />

      <ProtectedRoute role="senior_admin" path="/appeals" component={Appeals} />

      <ProtectedRoute
        role="owner"
        path="/appeal-statistics"
        component={AppealStatistics}
      />

      <Route path="/action/:type" component={Action} />

      <Route exact path="/players" component={Players} />
      <Route path="/players/:query" component={Players} />
      <Route path="/player/:steamid" component={Player} />
      <Route path="/player-stats-summary" component={PlayerStatsSummary} />

      <Route path="/teams" component={IDTeams} />
      <Route path="/player-teams" component={PlayerTeams} />

      <ProtectedRoute
        role="ip_ban_manager"
        path="/ip-bans"
        component={IPBans}
      />

      <ProtectedRoute
        role="ip_ban_manager"
        path="/expired-ip-bans"
        component={ExpiredIPBans}
      />

      <ProtectedRoute
        role="history_moderator"
        path="/review-bans"
        component={ReviewBans}
      />

      <ProtectedRoute role="owner" path="/owner/bans" component={Bans} />

      <ProtectedRoute
        role="owner"
        path="/owner/vpn-approved"
        component={VPNApproved}
      />

      <ProtectedRoute
        role="owner"
        path="/owner/server-updates"
        component={ServerUpdates}
      />

      <ProtectedRoute
        role="owner"
        path="/owner/server-versions"
        component={ServerVersions}
      />

      <ProtectedRoute
        role="owner"
        path="/owner/sessions"
        component={Sessions}
      />

      <Route path={"/tickets"} component={Tickets} />
      <Route path={"/ticket/:id"} component={Ticket} />

      <ProtectedRoute
        role="owner"
        path="/owner/creators"
        component={Creators}
      />

      <ProtectedRoute role="owner" path="/owner/admins" component={Admins} />

      <Redirect from="/" to="/home" exact={true} />

      <Route path="/whitelist-requests" component={WhitelistRequests} />
      <Route path="/whitelist-request/:id" component={WhitelistRequest} />

      <Route path="/spectates" component={Spectates} />

      <Redirect to="/" />
    </Switch>
  );
}
