import TimeAgo from "javascript-time-ago";
import en from "javascript-time-ago/locale/en";

TimeAgo.addLocale(en);

const timeAgo: TimeAgo = new TimeAgo("en-US");

export function activityFormatTimestamp(date: Date): string {
  let a: Date = new Date(date);
  let b: Date = new Date();

  if (
    a.getUTCDate() === b.getUTCDate() &&
    a.getUTCMonth() === b.getUTCMonth() &&
    a.getUTCFullYear() === b.getUTCFullYear()
  ) {
    return a.toLocaleTimeString();
  } else {
    return a.toLocaleString();
  }
}

export default timeAgo;
